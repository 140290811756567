const getters = {
    WORKSPACES_LIST: ({ workspacesList }) => workspacesList,
    WORKSPACE: ({ workspace }) => workspace,
    WORKSPACE_SESSIONS: ({ workspaceSessions }) => workspaceSessions,

    WORKSPACE_LIST_LOADING: ({ isWorkspacesListLoading }) => isWorkspacesListLoading,
    WORKSPACE_LOADING: ({ isWorkspaceLoading }) => isWorkspaceLoading,
    WORKSPACE_SESSIONS_LOADING: ({ isWorkspaceSessionsLoading }) => isWorkspaceSessionsLoading,
    SEND_MESSAGE_LOADING: ({ isSendMessageLoading }) => isSendMessageLoading,
    WORKSPACE_UPDATE_LOADING: ({ isWorkspaceUpdateLoading }) => isWorkspaceUpdateLoading,
    UPDATE_PARTICIPANTS_LOADING: ({ isUpdateParticipantsLoading }) => isUpdateParticipantsLoading,

    WORKSPACE_PROMPTS: ({ workspacePrompts }) => workspacePrompts || [],
    IS_WORKSPACE_PROMPTS_LOADING: ({ isWorkspacePromptsLoading }) => isWorkspacePromptsLoading,
    IS_WORKSPACE_PROMPTS_ACTION_LOADING: ({ isWorkspaceActionLoading }) => isWorkspaceActionLoading,
    SELECTED_WORKSPACE_PROMPT: ({ selectedPrompt }) => selectedPrompt || {},

    WORKSPACE_KNOWLEDGE_FILES: ({ workspaceKnowledgeFiles }) => workspaceKnowledgeFiles || [],
    WORKSPACE_KNOWLEDGE_FILES_LOADING: ({ isWorkspaceKnowledgeFileLoading }) => isWorkspaceKnowledgeFileLoading,
}

export default getters;